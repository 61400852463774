import React, { useEffect, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { Global } from '@emotion/react';
import { ScrollTop } from './components/common/ScrollTop/ScrollTop';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './layouts/Layout';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { MainTheme } from 'assets/styles/Theme';
import { GlobalStyles } from 'assets/styles/GlobalStyle';
import { PrivateRoute, PublicRoute } from 'routes';
import { useDispatch } from 'react-redux';
import { getFormType } from 'routes/signUp';
import { Loader } from 'components/ui/Loader/Loader';
import { lazy } from 'utils/lazy';
import { TerndingRoutes } from 'routes/trending';
import { ReportPage } from 'pages/ReportPage/ReportPage';
import { ShareReport } from 'utils/ShareReport';
import { setCurrentPath } from 'redux/redirectPath/currentPathSlice';

import DeleteAccountRequest from 'components/UserAccount/PersonalInfo/DeleteAccountRequest';

const AboutUs = lazy(() => import('pages/About/AboutUs'));
const Policy = lazy(() => import('pages/Policy/Policy'));
const Contacts = lazy(() => import('pages/Contacts/Contacts'));
const MapPage = lazy(() => import('pages/MapPage/MapPage'));
const UserAccountPage = lazy(() =>
  import('pages/UserAccountPage/UserAccountPage')
);
const CommunityPage = lazy(() => import('pages/CommunityPage/CommunityPage'));
const OsintToolPage = lazy(() => import('pages/OsintToolPage/OsintToolPage'));
const EnterprisePage = lazy(() =>
  import('pages/EnterprisePage/EnterprisePage')
);
const CoomingSoon = lazy(() => import('pages/ComingSoon/ComingSoon'));
const UpdateReport = lazy(() => import('./pages/Reports/EditReportPage'));
const ViewReportDetails = lazy(() =>
  import('pages/Reports/ViewReportDetailsPage')
);
const SignUpBox = lazy(() => import('components/common/SignUpMenu/SignUpBox'));
const HomePage = lazy(() => import('pages/MainPage/HomePage'));
// const LandingPage = lazy(() => import('pages/MainPage/LandingPage'));
const LogIn = lazy(() => import('components/form/AuthForms/LogForm'));
// const SignUp = lazy(() => import('components/form/AuthForms/RegForm'));
const Page404 = lazy(() => import('pages/404/Page404'));

export const App = () => {
  // const user = useSelector(selectUser);
  const location = useLocation();
  const path = location.pathname.replace('/sign-in', '');
  const formType = getFormType(path);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentPath = location.pathname;

    dispatch(setCurrentPath(currentPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    document.title = 'SleuthNet';
  }, []);

  return (
    <>
      <ThemeProvider theme={MainTheme}>
        <Global styles={GlobalStyles} />

        <CssBaseline />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  // user ? (
                  //   <PrivateRoute>
                  //     <HomePage />
                  //   </PrivateRoute>
                  // ) : (
                  //   <PublicRoute>
                  //     <LandingPage />
                  //   </PublicRoute>
                  // )
                  <PublicRoute>
                    <HomePage />
                  </PublicRoute>
                }
              />

              <Route
                path="sign-in/*"
                element={
                  <PublicRoute>
                    <SignUpBox isOpen={true} formType={formType} />
                  </PublicRoute>
                }
              >
                <Route path="login" element={<LogIn />} />

                {/* <Route path="register" element={<SignUp />} /> */}
              </Route>

              <Route
                path="community"
                element={
                  <PublicRoute>
                    <CommunityPage />
                  </PublicRoute>
                }
              />
              <Route
                path="osint-tool"
                element={
                  <PublicRoute>
                    <OsintToolPage />
                  </PublicRoute>
                }
              />
              <Route
                path="enterprise"
                element={
                  <PublicRoute>
                    <EnterprisePage />
                  </PublicRoute>
                }
              />
              <Route
                path="trending/:trendingCountry"
                element={
                  <PublicRoute>
                    <TerndingRoutes />
                  </PublicRoute>
                }
              />

              <Route
                path="about-us"
                element={
                  <PublicRoute>
                    <AboutUs />
                  </PublicRoute>
                }
              />
              <Route
                path="policy"
                element={
                  <PublicRoute>
                    <Policy />
                  </PublicRoute>
                }
              />
              <Route
                path="contacts"
                element={
                  <PublicRoute>
                    <Contacts />
                  </PublicRoute>
                }
              />

              <Route
                path="report/*"
                element={
                  <PrivateRoute>
                    <ReportPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="update-report"
                element={
                  <PrivateRoute>
                    <UpdateReport />
                  </PrivateRoute>
                }
              />

              <Route
                path="fullmap"
                element={
                  <PublicRoute>
                    <MapPage />
                  </PublicRoute>
                }
              />
              <Route
                path="account/*"
                element={
                  <PrivateRoute>
                    <UserAccountPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="report/view/:reportId"
                element={
                  <PublicRoute>
                    <ViewReportDetails />
                  </PublicRoute>
                }
              />

              <Route
                path="report/view/:reportId"
                element={
                  <PublicRoute>
                    <ShareReport />
                  </PublicRoute>
                }
              />

              <Route
                path="delete-account"
                element={
                  <PublicRoute>
                    <DeleteAccountRequest />
                  </PublicRoute>
                }
              />

              <Route
                path="coming-soon"
                element={
                  <PublicRoute>
                    <CoomingSoon />
                  </PublicRoute>
                }
              />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>
          <Toaster position="top-right" />
        </Suspense>
        <ScrollTop showBelow={200} />
      </ThemeProvider>
    </>
  );
};
