export const ROUTES = {
  login: {
    path: '/login',
    title: 'sign in',
  },
  register: {
    path: '/register',
    title: 'registration',
  },
  report: {
    path: '/report',
    title: 'report',
  },
  policy: {
    path: '/policy',
    title: 'privacy policy',
  },
  about: {
    path: '/about-us',
    title: 'about us',
  },
  contacts: {
    path: '/contacts',
    title: 'contacts',
  },
  account: {
    path: '/account',
    title: 'account',
  },
  fullmap: {
    path: '/fullMap',
    title: 'full-map',
  },
  community: {
    path: '/community',
    title: 'community',
  },
  osint: {
    path: '/osint-tool',
    title: 'osint-tool',
  },
  enterprise: {
    path: '/enterprise',
    title: 'enterprise',
  },
};
