export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const phoneRegExp = /^[+]{0,1}380([0-9]{9})$/;

export const passwordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?!.*\W).*$/;

export const breakPoints = {
  PHONE: 320,
  TABLET: 834,
  DESKTOP: 1280,
  UHD: 1440,
};

export const endpoints = [
  {
    name: 'uxo',
    btn: 'UXO',
    desc: 'Unexploded Bombs, Munitions, or Explosives',
  },
  {
    name: 'militaryActivity',
    btn: 'Military Activity',
    desc: 'Military movements',
  },
  { name: 'wildlife', btn: 'Wild Life', desc: 'Dangerous wild animals' },
  {
    name: 'warCrime',
    btn: 'War Crime',
    desc: 'Crimes committed by military personnel',
  },
  {
    name: 'criminalActivity',
    btn: 'Criminal Activity',
    desc: 'Civil Violence or crime',
  },
  {
    name: 'resourceScarcity',
    btn: 'Resource Scarcity',
    desc: 'Low supply and high demand of a limited resource',
  },
  {
    name: 'structureHazard',
    btn: 'Structure Hazard',
    desc: 'Building or structure hazards',
  },
  {
    name: 'naturalDisaster',
    btn: 'Natural Disaster',
    desc: 'Weather events that cause damage',
  },
  {
    name: 'terroristActivity',
    btn: 'Terrorist Activity',
    desc: 'Terrorist Related Activity',
  },
  {
    name: 'nasa',
    btn: 'NASA Reports',
    desc: 'Autogenerated reports from NASA',
  },
];

export const places = ['places'];

export const loadGoogleMapsScript = (apiKey, callback) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};

export const center_ua = {
  lat: 49.4037,
  lng: 31.40257,
};

export const typeOccupation = [
  {
    id: 1,
    name: 'Agriculture, Food, and Natural Resources',
  },

  {
    id: 2,
    name: 'Architecture and Construction',
  },
  {
    id: 3,
    name: 'Arts, Audio/Video Technology, and Communication',
  },
  {
    id: 4,
    name: 'Business and Finance',
  },
  {
    id: 5,
    name: 'Education and Training',
  },
  {
    id: 6,
    name: 'Government and Public Administration',
  },
  {
    id: 7,
    name: 'Health Science',
  },
  {
    id: 8,
    name: 'Information Technology ',
  },
  {
    id: 9,
    name: 'Law, Public Safety, Corrections, and Security',
  },
  {
    id: 10,
    name: 'Science, Technology, Engineering, and Math',
  },
];

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const FILE_SIZE = 10000000;

export const MAX_VIDEO_SIZE = 1024 * 1024 * 100; // 100 MB
export const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/avi', 'video/mkv'];

export const currentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = ('0' + (now.getMonth() + 1)).slice(-2);
  const day = ('0' + now.getDate()).slice(-2);
  const hours = ('0' + now.getHours()).slice(-2);
  const minutes = ('0' + now.getMinutes()).slice(-2);
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const reportDateTime = prop => {
  const now = new Date(prop);
  const year = now.getFullYear();
  const month = ('0' + (now.getMonth() + 1)).slice(-2);
  const day = ('0' + now.getDate()).slice(-2);
  const hours = ('0' + now.getHours()).slice(-2);
  const minutes = ('0' + now.getMinutes()).slice(-2);
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
