import { Box, CircularProgress } from '@mui/material';

const containerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgb(29, 29, 29, 0.5)',
  justifyContent: 'center',
};
export const Loader = () => {
  return (
    <Box style={containerStyle}>
      <CircularProgress color="primary" size={60} />
    </Box>
  );
};
