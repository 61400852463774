import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import { Container } from 'layouts/Container/Container';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { endpoints } from 'utils/constants';
import { setSelectedEndpoint } from 'redux/report/reportSlice';
import { UserReports } from 'components/common/UserReports/UserReports';
import './ChooseReport.styled.css';
// import AdvertisementBlock from 'components/ui/Advertisement/AdvertisementBlock';

const ChooseReport = () => {
  const { t } = useTranslation();
  const isScreenLessThan820px = useMediaQuery('(max-width:820px)');
  const isScreenLessThan1178px = useMediaQuery('(max-width:1178px)');
  const dispatch = useDispatch();

  return (
    <Container
      component="main"
      justifyContent="center"
      alignItems="normal"
      display="flex"
    >
      <Box
        sx={{
          flexDirection: `${isScreenLessThan1178px ? 'column' : 'row'}`,
          alignItems: 'normal',
        }}
      >
        <Box className="SelectReportsBox" marginBottom={'auto'}>
          <Box
            className="ReportPageBox"
            marginBottom={isScreenLessThan1178px && 2}
          >
            <Typography fontSize={11} textTransform="uppercase">
              {t('report.selection')}
            </Typography>
            <Box display={'block'}>
              <Typography variant="h2" marginY={2}>
                {t('report.title')}
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  flexWrap: 'wrap',
                  // maxWidth: 'md',
                }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {endpoints
                        .filter(({ name }) => name !== 'nasa')
                        .map(({ name, btn }, index) => (
                          <TableRow
                            key={name}
                            sx={{
                              backgroundColor:
                                index % 2 === 0
                                  ? 'rgba(45, 45, 45, 0.9) !important'
                                  : 'rgba(16, 16, 16, 0.05) !important',
                            }}
                          >
                            <TableCell sx={{ border: 'none' }}>
                              <Link to={`/report/${name}`}>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(setSelectedEndpoint({ name, btn }))
                                  }
                                  children={t('pageReport.select')}
                                  style={{ margin: 0 }}
                                />
                              </Link>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: `${
                                  isScreenLessThan820px ? 'wrap' : 'nowrap'
                                }`,
                              }}
                            >
                              {t(`btn.${name}`)}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: 'none',
                                whiteSpace: `${
                                  isScreenLessThan820px ? 'wrap' : 'nowrap'
                                }`,
                              }}
                            >
                              {t(`desc.${name}`)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          marginLeft={!isScreenLessThan1178px && 2}
          flexDirection={'column'}
          alignItems={'auto'}
          width={'100%'}
        >
          {/* <AdvertisementBlock
            style={{
              marginBottom: '1rem',
              width: '100%',
              height: '7rem',
              color: 'black',
            }}
            children={'Advertisement'}
          /> */}
          <Box
            className="UserReporstBox"
            // marginLeft={!isScreenLessThan1178px && 2}
          >
            <UserReports />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default ChooseReport;
