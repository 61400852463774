export async function getUserLocation() {
  try {
    if (navigator.geolocation) {
      const permissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (permissionStatus.state === 'granted') {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            error => {
              reject(error);
            }
          );
        });
      } else {
        return fetchUserLocationByIP();
      }
    } else {
      return fetchUserLocationByIP();
    }
  } catch (error) {
    console.error('Error fetching user location:', error);
    return null;
  }
}

export async function fetchUserLocationByIP() {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return { lat: data.latitude, lng: data.longitude };
  } catch (error) {
    console.error('Error fetching user location by IP:', error);
    return null;
  }
}
