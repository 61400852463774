import styled from '@emotion/styled';
import Switch from '@mui/material/Switch';

export const SwitchLanguageWrapper = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 6rem;
`;

export const SwitchLanguages = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 32,
  padding: 7,
  margin: 1,

  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#B9B9B9',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#D9D9D9',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#B9B9B9',
    width: 28,
    height: 28,
    border: '4px solid #fff',
    boxShadow: 'inset 1px 0px 3px 1px rgba(0,0,0,0.5)',
  },

  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#D9D9D9',
    borderRadius: 20 / 2,
  },
}));
