import { CustomButton, SecondCustomButton } from './Button.style';

export const MainButton = ({ type, onClick, children, disabled, style }) => {
  return (
    <CustomButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </CustomButton>
  );
};

export const SecondMainButton = ({
  type,
  onClick,
  children,
  disabled,
  style,
}) => {
  return (
    <SecondCustomButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </SecondCustomButton>
  );
};
