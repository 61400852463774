export const typeHazards = [
  {
    id: 1,
    name: 'warCrime',
    items: [
      { id: 'killingOfCivlians', name: 'Killing of Civilians' },
      { id: 'torture', name: 'Torture' },
      { id: 'sexualAssaultWar', name: 'Sexual Assault' },
      { id: 'forcedDeportation', name: 'Forced Deportation' },
      { id: 'attackOnCivlians', name: 'Attack on Civilians' },
      { id: 'attackProtectedSite', name: 'Attack of Protected Site' },
      { id: 'childSoldiers', name: 'Child Soldiers' },
      { id: 'starvationWarfare', name: 'Starvation as Warfare' },
      { id: 'humanShield', name: 'Human Shield' },
      { id: 'attackMedicalPersonnel', name: 'Attack on Medical Personnel' },
      { id: 'attackMediaPersonnel', name: 'Attack on media Personnel' },
      { id: 'chemicalWarfareWar', name: 'Chemical Warfare Agents' },
      { id: 'denialFairTrail', name: 'Denial of Fair Trial' },
      { id: 'lootingCivlianProperty', name: 'Looting Civilian Property' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 2,
    name: 'criminalActivity',
    items: [
      { id: 'riot', name: 'Riot' },
      { id: 'murder', name: 'Homicide' },
      { id: 'rape', name: 'Sexual Assault' },
      { id: 'kidnapping', name: 'Kidnapping' },
      { id: 'arson', name: 'Arson' },
      { id: 'robbery', name: 'Robbery' },
      { id: 'shooting', name: 'Shooting' },
      { id: 'bombing', name: 'Bombing' },
      { id: 'assault', name: 'Assault' },
      { id: 'hate', name: 'Hate Crime' },
      { id: 'carJacking', name: 'Car Jacking' },
      { id: 'home', name: 'Home Invasion' },
      { id: 'gangViolence', name: 'Gang Violence' },
      { id: 'humanTrafficking', name: 'Human Trafficking' },
      { id: 'looting', name: 'Looting' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 3,
    name: 'structureHazard',
    items: [
      { id: 'structuralDamage', name: 'Structural Damage' },
      { id: 'structureFire', name: 'Structure Fire' },
      { id: 'downPowerLine', name: 'Down Power Line' },
      { id: 'waterHazard', name: 'Water Hazard' },
      { id: 'roadWashout', name: 'Road Washout' },
      { id: 'gasLeak', name: 'Gas Leak' },
      { id: 'collapse', name: 'Structure collapse' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 4,
    name: 'naturalDisaster',
    items: [
      { id: 'flood', name: 'Flood' },
      { id: 'wildFire', name: 'Wild Fire' },
      { id: 'hurricane', name: 'Hurricane' },
      { id: 'tornado', name: 'Tornado' },
      { id: 'landSlide', name: 'Land Slide' },
      { id: 'earthquake', name: 'Earthquake' },
      { id: 'tsunami', name: 'Tsunami' },
      { id: 'volcanoes', name: 'Volcanic Eruption' },
      { id: 'drought', name: 'Drought' },
      { id: 'avalanche', name: 'Avalanche' },
      { id: 'extremeHeat', name: 'Heatwave' },
      { id: 'extremeCold', name: 'Cold Wave' },
      { id: 'blizzard', name: 'Blizzard' },
      { id: 'hailStorm', name: 'Hailstorm' },
      { id: 'sinkHole', name: 'Sinkhole' },
      { id: 'sandStorm', name: 'Sand Storm' },
      { id: 'severeStorm', name: 'Severe Storm' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 5,
    name: 'wildlife',
    items: [
      { id: 'alligatorCrocodile', name: 'Alligator/Crocodile' },
      { id: 'bear', name: 'Bear' },
      { id: 'bees', name: 'Bees' },
      { id: 'boar', name: 'Boar' },
      { id: 'buffalo', name: 'Buffalo' },
      { id: 'dangerousCat', name: 'Dangerous Cat' },
      { id: 'deerElkAntelope', name: 'Deer/Elk/Antelope' },
      { id: 'elephant', name: 'Elephant' },
      { id: 'hippopotamus', name: 'Hippopotamus' },
      { id: 'moose', name: 'Moose' },
      { id: 'shark', name: 'Shark' },
      { id: 'wasps', name: 'Wasps / Hornets' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 6,
    name: 'terroristActivity',
    items: [
      { id: 'attacksOnCivilians', name: 'Attacks on Civilians' },
      { id: 'attacksOnInfrasctucture', name: 'Attacks on Infrastructure' },
      { id: 'hostageTakingKidnapping', name: 'Hostage-taking and kidnapping' },
      { id: 'bombingTerrorist', name: 'Bombings and explosions' },
      { id: 'assassination', name: 'Assassinations and targeted killings' },
      { id: 'aircraftHijacking', name: 'Hijackings and aircraft attacks' },
      { id: 'cyberAttack', name: 'Cyberattacks and electronic warfare' },
      { id: 'intimidation', name: 'Intimidation and harassment' },
      { id: 'recruitment', name: 'Recruitment and radicalization' },
      { id: 'financing', name: 'Financing and fundraising' },
      { id: 'propoganda', name: 'Propaganda and dissemination of ideology' },
      { id: 'checkpointsTerrorist', name: 'Illegal Checkpoint' },
      { id: 'nightLetters', name: 'Night Letters' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 7,
    name: 'humanTrafficking',
    items: [
      { id: 'minorKidnapping', name: 'Kidnapping (child/teen)' },
      { id: 'adultKidnapping', name: 'Kidnapping (Adult)' },
      { id: 'sexualAssault', name: 'Sexual Assault' },
      { id: 'prostitution', name: 'Prostitution' },
    ],
  },
  {
    id: 8,
    name: 'militaryActivity',
    items: [
      { id: 'peacekeepingMissions', name: 'Peacekeeping Missions' },
      { id: 'combatOperations', name: 'Combat Operations' },
      { id: 'reconAndSurveillance', name: 'Reconnaissance and Surveillance' },
      {
        id: 'militaryTrainingExercise',
        name: 'Military Training and Exercises',
      },
      { id: 'hadr', name: 'Humanitarian Aid and Disaster Relief (HA/DR)' },
      { id: 'securityPatrols', name: 'Security Patrols' },
      { id: 'counterTerrorismOperations', name: 'Counterterrorism Operations' },
      { id: 'cyberWarefare', name: 'Cyber Warfare' },
      { id: 'logisitcs', name: 'Logistics and Support' },
      { id: 'eod', name: 'Demining and Explosive Ordnance Disposal (EOD)' },
      { id: 'psyops', name: 'Psychological Operations (PsyOps)' },
      { id: 'searchAndRescue', name: 'Search and Rescue Operations' },
      { id: 'navalBlockade', name: 'Naval Blockades' },
      { id: 'airDefenseOperations', name: 'Air Defense Operations' },
      {
        id: 'counterinsurgencyOperations',
        name: 'Counterinsurgency Operations',
      },
      { id: 'electronicWarfare', name: 'Electronic Warfare' },
      {
        id: 'intelligenceOperations',
        name: 'Military Intelligence Operations',
      },
      { id: 'antiPiracyOperations', name: 'Anti-Piracy Operations' },
      { id: 'specialForcesOperations', name: 'Special Forces Operations' },
      { id: 'droneStrikes', name: 'Drone Strikes' },
      { id: 'droneSurveillience', name: 'Drone Surveillience' },
      { id: 'ambush', name: 'Ambush' },
      { id: 'sead', name: 'Suppression of Enemy Air Defenses (SEAD)' },
      {
        id: 'militaryAdvisoryTraining',
        name: 'Military Advisory and Training Missions',
      },
      {
        id: 'armsControlDisarmament',
        name: 'Arms Control and Disarmament Efforts',
      },
      { id: 'medicalServices', name: 'Medical Services' },
      { id: 'engineeringConstruction', name: 'Engineering and Construction' },
      { id: 'nuclearDeterrence', name: 'Nuclear Deterrence Operations' },
      { id: 'jointMultinationalOps', name: 'Joint Multinational Operations' },
      { id: 'counterNarcotics', name: 'Counter-Narcotics Operations' },
      { id: 'maritimeSecurity', name: 'Maritime Security Operations' },
      { id: 'airborneOps', name: 'Airborne Operations' },
      { id: 'amphibiousWarfare', name: 'Amphibious Warfare Operations' },
      { id: 'infantryEngagements', name: 'Infantry Engagements' },
      { id: 'armoredWarfare', name: 'Armored Warfare' },
      { id: 'artilleryStrikes', name: 'Artillery Strikes' },
      { id: 'aerialDogfights', name: 'Aerial Dogfights' },
      { id: 'navalEngagements', name: 'Naval Engagements' },
      { id: 'submarineWarfare', name: 'Submarine Warfare' },
      { id: 'urbanCombat', name: 'Urban Combat' },
      { id: 'guerrillaWarfare', name: 'Guerrilla Warfare' },
      { id: 'tunnelWarfare', name: 'Tunnel Warfare' },
      { id: 'mountainWarfare', name: 'Mountain Warfare' },
      { id: 'jungleWarfare', name: 'Jungle Warfare' },
      { id: 'desertWarfare', name: 'Desert Warfare' },
      { id: 'arcticWarfare', name: 'Arctic Warfare' },
      { id: 'siegeOperations', name: 'Siege Operations' },
      { id: 'precisionStrikes', name: 'Precision Strikes' },
      { id: 'carrierStrikeOperations', name: 'Carrier Strike Operations' },
      { id: 'strategicBombing', name: 'Strategic Bombing' },
      { id: 'antiSubmarineWarfare', name: 'Anti-Submarine Warfare' },
      { id: 'antiAirWarfare', name: 'Anti-Air Warfare' },
      {
        id: 'cbrnWarfare',
        name: 'Chemical, Biological, Radiological, and Nuclear Warfare (CBRN)',
      },
      { id: 'informationWarfare', name: 'Information Warfare' },
      { id: 'closeAirSupport', name: 'Close Air Support' },
      { id: 'searchAndDestroy', name: 'Search and Destroy Missions' },
      { id: 'decapitationStrikes', name: 'Decapitation Strikes' },
      { id: 'militaryCheckpoint', name: 'Checkpoint' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
  {
    id: 9,
    name: 'resourceScarcity',
    items: [
      { id: 'fuelOutage', name: 'No Fuel' },
      { id: 'powerOutage', name: 'No Power' },
      { id: 'cellularOutage', name: 'No Cellular Service' },
      { id: 'foodOutage', name: 'No Food' },
      { id: 'waterOutage', name: 'No Water' },
      { id: 'loadShed', name: 'Load Shedding' },
      { id: 'other', name: 'Other (specify in the comment)' },
    ],
  },
];
