import { css } from '@emotion/react';
import Ethnocentric from 'assets/fonts/Ethnocentric.woff';
import Montserrat from 'assets/fonts/Montserrat-Regular.ttf';
import Inter from 'assets/fonts/Inter-Regular.ttf';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const GlobalStyles = css`
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${Montserrat}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    font-display: swap;
  }
  @font-face {
    font-family: 'Ethnocentric';
    src: url(${Ethnocentric}) format('truetype');
    font-weight: normal;
    /* font-weight: 700; */
    font-style: normal;
    font-display: auto;
    font-display: swap;
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875;
    line-height: 1.625;
    position: relative;
    color: #d2d2d2;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 0;
    color: #d2d2d2;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    outline: none;
  }

  button {
    padding: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }
  .MuiButton-root {
    color: #ffffff !important;
  }

  .MuiButtonBase-root:disabled {
    cursor: not-allowed !important;
    pointer-events: auto;
  }

  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root.Mui-active {
    color: #133a56 !important;
  }

  .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    color: #2196f3;
    border: none !important;
  }

  .MuiRadio-root.Mui-checked .MuiSvgIcon-root {
    color: #2196f3;
    border: none !important;
    background-color: #2196f3 !important;
    border-radius: 50%;
    background-image: #2196f3 !important;
  }

  .MuiRadio-root .MuiSvgIcon-root {
    color: #d2d2d2 !important;
    background-color: #d2d2d2 !important;
    border-radius: 50%;
  }

  .Mui-checked .MuiRadio-root {
    color: #2196f3 !important;
    border: none !important;
  }
  .MuiBox-root {
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .MuiGrid-item {
    /* padding: 0 !important; */
  }
  .MuiInput-underline::before {
    border-color: #2196f3 !important;
  }
  .MuiInput-underline::after {
    border-color: #133a56;
  }

  /*View Report Section */

  .reportTitleSection {
    text-transform: uppercase;
    font-weight: bold;
    color: #666666;
    padding-right: 8px;
  }

  .redacted-text-block {
    background-color: #313131;
    border: none;
    padding: 10px;
    font-size: 16px;
    color: #666666;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 5px 5px rgba(49, 49, 49, 1);
    margin: 10px 0 10px 0;
    text-align: center;
    vertical-align: middle;
  }

  .readacted-text-block::placeholder {
    color: #999999;
  }
`;

export const StyledInput = styled(TextField)`
  width: 100%;
  height: 100%;
  /* height: 2.5rem; */
  margin-top: 8px;
  text-align: normal;
`;
