import { MainAppBar } from 'components/common/AppBar/MainAppBar';
import { Outlet } from 'react-router-dom';
import { Footer } from 'components/common/Footer/Footer';
import { Box } from '@mui/material';

export const Layout = () => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'normal',
      }}
    >
      <MainAppBar />
      <main>
        <Outlet />
      </main>

      <Footer />
    </Box>
  );
};
