import { useState, useEffect } from 'react';
import { IconButton, CircularProgress, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PhotoViewer = ({ photos, open, onClose }) => {
  const [loading, setLoading] = useState(true);
  const morePtotos = photos.length > 1;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomed, setZoomed] = useState(true);
  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % photos.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };
  const handleZoom = () => {
    setZoomed(!zoomed);
  };
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [photos]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          minWidth: '360px',
          maxWidth: '800px',
          height: '80vh',
          padding: '16px',
          backgroundColor: '#1d1d1d',
          outline: 'none',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            transition: 'background-color 0.3s',
            ':hover': {
              backgroundColor: '#2196f3',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          <Box
            sx={{
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {morePtotos && (
              <IconButton
                aria-label="previous"
                onClick={handlePrevious}
                sx={{
                  color: '#ffffff',
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  transform: 'translateY(-50%)',
                  transition: 'background-color 0.3s',
                  ':hover': {
                    backgroundColor: '#2196f3',
                  },
                }}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            )}
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Slide ${index}`}
                style={{
                  maxWidth: zoomed ? '100%' : 'auto',
                  maxHeight: zoomed ? '100%' : 'auto',
                  width: zoomed ? 'auto' : '100%',
                  height: zoomed ? 'auto' : '100%',
                  objectFit: zoomed ? 'contain' : 'none',
                  display: index === currentIndex ? 'block' : 'none',
                }}
                onClick={handleZoom}
              />
            ))}
            {morePtotos && (
              <IconButton
                aria-label="next"
                onClick={handleNext}
                sx={{
                  color: '#ffffff',
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  transition: 'background-color 0.3s',
                  ':hover': {
                    backgroundColor: '#2196f3',
                  },
                }}
              >
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PhotoViewer;
