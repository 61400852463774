import React from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const DeleteAccountRequest = () => {
  return (
    <Container>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant='h1' sx={{ paddingBottom: '24px', paddingTop:'24px' }}>DELETE YOUR SENTINEL AND SLEUTHNET ACCOUNT</Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12} sx={{paddingTop:'18px'}}>
                <Box>
                  <Typography variant='body2' sx={{paddingTop:'14px'}}>You must be logged in to delete your account.</Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12} sx={{paddingTop: '66px'}}>
                <Box>
                  <Link to="/policy"><Button>Review Our Privacy Policy</Button></Link>
                </Box>
              </Grid>
            </Grid>
        </Grid>    
    </Container>
  )
}

export default DeleteAccountRequest