import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from '../redux/auth/authSlice';

export function PublicRoute({
  children,
  restricted = false,
  redirectTo = '/',
  ...routeProps
}) {
  const user = useSelector(selectUser);
  const shouldRedirect = user && restricted;
  return (
    <>
      {shouldRedirect ? <Navigate to={redirectTo} replace={false} /> : children}
    </>
  );
}
