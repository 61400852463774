import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPath: '/',
};

export const currentPathSlice = createSlice({
  name: 'currentPath',
  initialState,
  reducers: {
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
    },
  },
});

export const { setCurrentPath } = currentPathSlice.actions;

export const selectCurrentPath = state => state.currentPath.currentPath;

export default currentPathSlice.reducer;
