import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const CustomButton = styled(Button)`
  max-width: 260px;
  min-width: 100px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  padding: 8px 16px;
  text-decoration: none;
  outline: none;
  white-space: wrap;
  background-color: #2196f3;
  border: 1px solid #2e4f66;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #ffff !important;
  font-size: 16px;
  font-family: 'Inter';
  line-height: 1.5;

  &:hover {
    /* border: 1px solid #ffff; */
    box-shadow: none;
    background-color: #16629e;
  }
  &:disabled {
    cursor: not-allowed !important;
  }

  box-sizing: border-box;
`;

export const SecondCustomButton = styled(Button)`
  max-width: 260px;
  min-width: 100px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  padding: 8px 16px;
  text-decoration: none;
  outline: none;
  white-space: wrap;
  background: inherit;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #d2d2d2;
  font-size: 16px;
  font-family: 'Inter';
  line-height: 1.5;

  &:hover {
    /* border: 1px solid #ffff; */
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
  }

  box-sizing: border-box;
`;
