import { createSlice } from '@reduxjs/toolkit';

const reportSlice = createSlice({
  name: 'report',
  initialState: null,
  reducers: {
    setSelectedEndpoint: (state, action) => {
      return action.payload;
    },
    clearSelectedEndpoint: state => {
      return null;
    },
  },
});

export const { setSelectedEndpoint, clearSelectedEndpoint } =
  reportSlice.actions;

export default reportSlice.reducer;
