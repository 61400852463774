import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  IconButton,
  Box,
  Typography,
  Modal,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  MenuItem,
  Button,
  Grid,
  useMediaQuery,
  Stack,
  Autocomplete,
  Select,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from 'services/firebase';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/authSlice';
import { StyledInput } from 'assets/styles/GlobalStyle';
import Geocode from 'react-geocode';
import './ReportForm.styled.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import { validationMediaSchema } from 'utils/validationSchema';
import { countries } from 'utils/constants';
import { typeHazards } from 'utils/type';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { nanoid } from 'nanoid';
import SubMapComponent from 'components/common/Map/SubMapComponent';
const MilitaryReportForm = ({
  openMapModal,
  selectedMapLocation,
  selectedLatLng,
  setSelectedMapLocation,
  locationInfo,
  userLocation,
}) => {
  const { name } = useSelector(state => state.report);
  const [, setSelectedLocation] = useState(null);
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [openSubmit, setOpenSubmit] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenSubmit(false);
    resetForm();
    navigate('/account/my-reports');
  };
  const [loading, setLoading] = useState(false);
  const [, setInputCoordinates] = useState(null);
  const [inputVideos, setInputVideos] = useState([]);

  const handleInputCoordinatesChange = event => {
    const coordinates = event.target.value;
    const isValid = coordinates.split(',').length === 2;
    const lat = parseFloat(coordinates.split(',')[0]);
    const lng = parseFloat(coordinates.split(',')[1]);

    if (isValid) {
      Geocode.fromLocation({ lat, lng }).then(response => {
        const result = response.results[0];
        const location = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        };
        setSelectedMapLocation(location);
        setInputCoordinates(location);
      });
    }
  };
  const storage = getStorage();

  const handleInputVideoChange = async e => {
    const videoFiles = Array.from(e.currentTarget.files);
    setFieldValue('video', [...(values.video || []), ...videoFiles]);
    setInputVideos([...inputVideos, ...videoFiles]);
  };

  const typeHazard = typeHazards.find(
    typeHazard => typeHazard.name === 'militaryActivityReport'
  );
  const [, setOpenType] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState('');
  const [selectedTypeName, setSelectedTypeName] = useState('');

  const handleSelectType = id => {
    const selectedItem = typeHazard.items.find(item => item.id === id);
    setSelectedTypeId(id);
    setSelectedTypeName(
      selectedItem ? t(`typeHazards.militaryActivity.items.${id}`) : ''
    );
    setFieldValue('type', selectedItem ? selectedItem.name : '');
    setOpenType(true);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    errors,
    setErrors,
    resetForm,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      timeObserved: null,
      location: '',
      contactMethod: '',
      contactNumber: '',
      type: '',
      resourcesThreatened: '',
      protective: '',
      priority: 0,
      size: 0,
      unitCountry: '',
      uniformDescription: '',
      equipmentObserved: '',
      originalSocialMediaLink: '',
      photo: [],
      video: [],
    },
    validationSchema: validationMediaSchema,
    onSubmit: async values => {
      try {
        setLoading(true);
        const writeUserData = async (values, selectedLatLng) => {
          const docData = {
            latitude: selectedLatLng.lat,
            longitude: selectedLatLng.lng,
            city: locationInfo.city,
            state: locationInfo.state,
            country: locationInfo.country,
            timeObserved: values.timeObserved
              ? Timestamp.fromDate(new Date(values.timeObserved))
              : Timestamp.fromDate(new Date()),
            contactMethod: values.contactMethod,
            contactNumber: values.contactNumber,
            unitCountry: values.unitCountry,
            size: parseInt(values.size),
            equipment: values.equipmentObserved,
            priority: parseInt(values.priority),
            description: values.uniformDescription,
            reportingApp: 'SleuthNet',
            type: values.type,
            photoUrl: [],
            videoUrl: [],
            docPdfUrl: [],
            originalSocialMediaLink: values.originalSocialMediaLink,
            timeReported: Timestamp.fromDate(new Date()),
            reportType: 'militaryActivity',
            reportName: 'Military Activity',
            reportId: '',
            originalReportUserId: user,
            archived: false,
            verificationStatus: 0,
            verifiedByUserId: user,
            verificationTimestamp: Timestamp.fromDate(new Date()),
            verificationUpVotes: 0,
            verificationDownVotes: 0,
            safetyStatus: 'unknown',
            closedForComments: false,
            closedForUpdates: false,
          };

          try {
            const docRef = await addDoc(collection(db, 'Reports'), {
              ...docData,
            });

            const uploadPromises = values.photo.map(async (photo, index) => {
              const photoName = `${user}_${index + 1}`;
              const storageRef = ref(
                storage,
                `Reports/militaryActivity/${docRef.id}/${photoName}`
              );

              await uploadBytes(storageRef, photo);
              return getDownloadURL(storageRef);
            });

            const photoUrls = await Promise.all(uploadPromises);

            if (inputVideos.length > 0) {
              const videoUrls = [];
              let videoCount = 1;
              for (const video of inputVideos) {
                const videoName = `${user}_video_${videoCount}`;
                const videoStorageRef = ref(
                  storage,
                  `Reports/militaryActivity/${docRef.id}/${videoName}`
                );
                await uploadBytes(videoStorageRef, video);
                const videoUrl = await getDownloadURL(videoStorageRef);
                videoUrls.push(videoUrl);
                videoCount++;
              }
              values.videoUrl = videoUrls;
            }

            await setDoc(
              docRef,
              {
                reportId: docRef.id,
                photoUrl: photoUrls || [],
                videoUrl: values.videoUrl || [],
              },
              { merge: true }
            );

            console.log('Document written with ID: ', docRef.id);
          } catch (error) {
            console.error('Error adding document: ', error);
          }
        };

        await writeUserData(values, selectedLatLng);
        setLoading(false);
        setSelectedLocation(null);
        setOpenSubmit(true);
      } catch (error) {
        setLoading(false);
        toast.error('Error:', error, {
          duration: 4000,
          position: 'top-center',
        });
        console.log(error);
      }
    },
  });

  const isScreenLessThan900px = useMediaQuery('(max-width:899px)');

  return (
    <Box
      noValidate
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      className="ReportFormBox"
    >
      <Typography variant="h2">
        {t('report.create')} {t(`btn.${name}`)}
      </Typography>

      <Grid container>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              required
              disableFuture
              ampm={false}
              label={t('report.time')}
              name="timeObserved"
              value={values.timeObserved}
              onChange={date => setFieldValue('timeObserved', date)}
              slotProps={{ textField: { variant: 'standard' } }}
              sx={{
                marginY: 2,
                width: '100%',
                height: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>

        {isScreenLessThan900px && (
          <Box
            sx={{
              flex: 1,
              minWidth: 320,
              width: '100%',
              height: 200,
              bgcolor: '#1d1d1d',
              display: 'block',
            }}
          >
            <SubMapComponent
              defaultCenter={selectedMapLocation || userLocation}
              position={selectedMapLocation}
            />
          </Box>
        )}
        <Grid item container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={8}>
            {selectedMapLocation ? (
              <StyledInput
                fullWidth
                required
                variant="standard"
                label={t('report.location')}
                name="location"
                value={
                  selectedLatLng
                    ? `${selectedLatLng.lat}, ${selectedLatLng.lng}`
                    : values.location
                }
                onChange={handleInputCoordinatesChange}
              />
            ) : (
              <Typography
                sx={{
                  width: '100%',
                  height: '100%',

                  marginTop: 1,
                  textAlign: 'normal',
                }}
              >
                {t('report.choiceLocation')}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={openMapModal}
              children={t('report.select')}
              style={{ height: '100%' }}
            />
          </Grid>
        </Grid>
        <FormControl component="fieldset" fullWidth>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'normal',
            }}
          >
            <FormLabel component="legend" sx={{ mr: 2 }}>
              {t('report.contactMethod')}
            </FormLabel>

            <Box>
              <FormControlLabel
                style={{ fontSize: '14px' }}
                control={
                  <Radio
                    size="small"
                    checked={values.contactMethod === 'Do Not Contact'}
                    onChange={handleChange}
                    name="contactMethod"
                    value="Do Not Contact"
                  />
                }
                label={
                  <span style={{ fontSize: '14px' }}>
                    {t('report.doNotContact')}
                  </span>
                }
              />

              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    checked={values.contactMethod === 'Phone'}
                    onChange={handleChange}
                    name="contactMethod"
                    value="Phone"
                  />
                }
                label={
                  <span style={{ fontSize: '14px' }}>{t('report.phone')}</span>
                }
              />
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    checked={values.contactMethod === 'Radio Frequency'}
                    onChange={handleChange}
                    name="contactMethod"
                    value="Radio Frequency"
                  />
                }
                label={
                  <span style={{ fontSize: '14px' }}>{t('report.radio')}</span>
                }
              />
            </Box>
          </Box>

          <StyledInput
            sx={{
              mb: 2,
              width: '60%',
            }}
            required
            variant="standard"
            label={t('report.choiceMetod')}
            name="contactNumber"
            onChange={handleChange}
            value={values.contactNumber}
          />
        </FormControl>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={5}>
            <Select
              fullWidth
              className="CustomSelect"
              id="type"
              name="type"
              value={selectedTypeId}
              displayEmpty
              onChange={e => handleSelectType(e.target.value)}
            >
              <MenuItem value="">{t('report.selectType')}</MenuItem>
              {typeHazard &&
                typeHazard.items.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(`typeHazards.${typeHazard.name}.items.${item.id}`)}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12} md={7}>
            <StyledInput
              sx={{
                mb: 2,
              }}
              required
              label={t('report.yourSelect')}
              variant="standard"
              value={selectedTypeName}
            />
          </Grid>
          {selectedTypeId === 'other' && (
            <Grid item xs={12}>
              <StyledInput
                required
                fullWidth
                variant="standard"
                label={t('report.type')}
                name="type"
                value={values.type}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Autocomplete
              options={countries}
              renderInput={params => (
                <StyledInput
                  {...params}
                  label={t('report.unit')}
                  variant="standard"
                  fullWidth
                  required
                />
              )}
              value={values.uunitCountryit}
              onChange={(event, newValue) => {
                setFieldValue('unitCountry', newValue);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  <Typography variant="body1">
                    {t(`countries.${option}`)}
                  </Typography>
                </MenuItem>
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledInput
              fullWidth
              variant="standard"
              id="size"
              label={t('report.size')}
              name="size"
              autoComplete="off"
              value={values.size}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="uniformDescription"
            label={t('report.add-comment')}
            name="uniformDescription"
            autoComplete="off"
            value={values.uniformDescription}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="equipmentObserved"
            label={t('report.equipmentObserved')}
            name="equipmentObserved"
            autoComplete="off"
            value={values.equipmentObserved}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'normal',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  mr: 2,
                }}
              >
                {t('report.priority')}
              </FormLabel>
              <Box>
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '2'}
                      onChange={handleChange}
                      name="priority"
                      value={2}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>{t('report.high')}</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '1'}
                      onChange={handleChange}
                      name="priority"
                      value={1}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('report.medium')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '0'}
                      onChange={handleChange}
                      name="priority"
                      value={0}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>{t('report.low')}</span>
                  }
                />
              </Box>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            type="url"
            variant="standard"
            id="originalSocialMediaLink"
            name="originalSocialMediaLink"
            label={t('report.social-media-link')}
            autoComplete="off"
            value={values.originalSocialMediaLink}
            onChange={handleChange}
            sx={{
              mb: 1,
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.photo ? 2 : 1,
              borderColor: errors.photo ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('photo', []);
                setErrors({ ...errors, photo: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {values.photo.length === 0 ? (
                <Typography
                  className="UploadPlaceholder"
                  children={t('report.UploadYourPhoto')}
                />
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {values.photo.map(file => (
                    <div key={file.name}> - {file.name}; </div>
                  ))}
                </div>
              )}
            </div>
            {errors.photo && (
              <span className="UploadError">{errors.photo} </span>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.photo ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}> {t('report.photo')} </Typography>
              <input
                hidden
                name="photo"
                id="photo"
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple
                onChange={e => {
                  const files = Array.from(e.currentTarget.files);
                  setFieldValue('photo', [...(values.photo || []), ...files]);
                }}
              />
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.video ? 2 : 1,
              borderColor: errors.video ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('video', []);
                setInputVideos([]);
                setErrors({ ...errors, video: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {inputVideos.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {inputVideos.map(video => (
                    <div key={nanoid()}>{video.name}</div>
                  ))}
                </div>
              ) : (
                <Typography
                  className="UploadPlaceholder"
                  children={t('report.UploadYourVideo')}
                />
              )}
            </div>
            {errors.video && (
              <span className="UploadError">{errors.video} </span>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.video ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}> {t('report.video')}</Typography>

              <input
                hidden
                name="video"
                id="video"
                type="file"
                accept=".mp4, .avi, .mkv"
                onChange={handleInputVideoChange}
              />
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Stack spacing={2} direction="row" marginTop={2}>
            <Button
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty)}
              style={{ minWidth: 176 }}
            >
              {loading ? (
                <CircularProgress color={'secondary'} size={24} />
              ) : (
                `${t('report.submit')}`
              )}
            </Button>
            <Button
              variant="outlined"
              type="reset"
              onClick={e => resetForm()}
              // disabled={!(formik.isValid && formik.dirty)}
              children={t('report.clear')}
            />
          </Stack>
        </Grid>
      </Grid>
      <Modal
        open={openSubmit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="SubmitedReporttModal">
          <IconButton
            aria-label="close"
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: '-20%',
              left: '100%',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ fontWeight: 'bold', mb: 2 }}
          >
            "{t(`btn.${name}`)}" {t('report.reportSubmitted')}
          </Typography>
          <Typography id="modal-modal-description">
            {t('report.rSdesc')}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default MilitaryReportForm;
