import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

export const ToTop = styled(IconButton)`
    z-index: 10;
    position: fixed;
    bottom: 2vh;
    right: 2vh;
    background-color: #DCDCDC;
    color: black;
    &:hover, &.mui-focusVisible: {
      transition: 0.3s;
      color: #397BA6;     
      background-color: '#2196f3',
                 
    },
    
`;
