import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  ListItemButton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { UserLogOut } from 'components/common/UserMenu/UserLogOut';
import { endpoints } from 'utils/constants';
import { setSelectedEndpoint } from 'redux/report/reportSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MenuIcon } from 'assets/img/menu-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/close-menu-icon.svg';
import './SideBar.styled.css';
import { selectUser } from 'redux/auth/authSlice';

export const SideBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const user = useSelector(selectUser);

  const toggleMenu = event => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = event => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChildItemClick = () => {
    closeMenu();
    setSelectedItem(null);
  };

  return (
    <Box>
      <IconButton onClick={toggleMenu} color="inherit" sx={{ p: 1.5 }}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      {isOpen && (
        <Box ref={sidebarRef} className="SideBarWrapper">
          <List>
            <ListItem className="listItem">
              <List>
                <ListItem className="listItem">
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    fontSize={'16px'}
                    primary={t('mainSideBar.trending')}
                  />{' '}
                </ListItem>

                <List
                  // component="div"
                  sx={{
                    px: '2rem',
                    textTransform: 'uppercase',
                  }}
                >
                  <ListItemButton
                    sx={{
                      py: '4px',
                    }}
                    component={Link}
                    to="/trending/ukraine"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('countries.Ukraine')}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      py: '4px',
                    }}
                    component={Link}
                    to="/trending/israel"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('countries.Israel')}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      py: '4px',
                    }}
                    component={Link}
                    to="/coming-soon"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('mainSideBar.hurricane')}
                    </Typography>
                  </ListItemButton>
                </List>
              </List>
            </ListItem>
            {user && (
              <ListItem className="listItem">
                <List>
                  <ListItem className="listItem">
                    <ListItemText
                      sx={{ textTransform: 'uppercase' }}
                      fontSize={'16px'}
                      primary={t('mainSideBar.report')}
                    />
                  </ListItem>

                  <List component="div" sx={{ px: '2rem' }}>
                    {endpoints
                      .filter(({ name }) => name !== 'nasa')
                      .map(endpoint => (
                        <ListItemButton
                          component={Link}
                          to={`/report/${endpoint.name}`}
                          key={endpoint.name}
                          sx={{ textTransform: 'uppercase', py: '8px' }}
                          onClick={() => {
                            dispatch(setSelectedEndpoint(endpoint));
                            handleChildItemClick();
                          }}
                        >
                          <Typography fontSize={14}>
                            {t(`btn.${endpoint.name}`)}
                          </Typography>
                        </ListItemButton>
                      ))}
                  </List>
                </List>
              </ListItem>
            )}

            <ListItem className="listItem">
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                component={Link}
                to="/coming-soon"
              >
                <Typography
                  sx={{ textTransform: 'uppercase' }}
                  fontSize={'16px'}
                >
                  {t('mainSideBar.forum')}
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem className="listItem">
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                component={Link}
                to="/coming-soon"
              >
                <Typography
                  sx={{ textTransform: 'uppercase' }}
                  fontSize={'16px'}
                >
                  {t('appBar.osint')}
                </Typography>
              </ListItemButton>
            </ListItem>

            {user && (
              <ListItem className="listItem">
                <List>
                  <ListItem className="listItem">
                    <ListItemText
                      sx={{ textTransform: 'uppercase' }}
                      fontSize={'16px'}
                      primary={t('mainSideBar.account')}
                    />{' '}
                  </ListItem>

                  <List
                    // component="div"
                    sx={{
                      px: '2rem',
                      textTransform: 'uppercase',
                    }}
                  >
                    <ListItemButton
                      sx={{
                        py: '4px',
                      }}
                      component={Link}
                      to="/account"
                      onClick={handleChildItemClick}
                    >
                      <Typography fontSize={14}>
                        {t('mainSideBar.edit')}
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      sx={{
                        py: '4px',
                      }}
                      component={Link}
                      to="/account/my-reports"
                      onClick={handleChildItemClick}
                    >
                      <Typography fontSize={14}>
                        {t('mainSideBar.myReport')}
                      </Typography>
                    </ListItemButton>
                  </List>
                </List>
              </ListItem>
            )}

            <Divider />
            {user && (
              <ListItem className="listItem">
                <ListItemButton sx={{ padding: 0, mt: 2 }}>
                  <UserLogOut
                    label={t('userInfo.logout')}
                    styled={{
                      width: '100%',
                      textTransform: 'uppercase',
                      justifyContent: 'normal',
                      padding: '8px 16px',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};
