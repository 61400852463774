export const getFormType = path => {
  switch (path) {
    case '/login':
      return 'login';
    case '/register':
      return 'register';
    case '/forgot-password':
      return 'forgotPassword';
    case '/reset-password':
      return 'resetPassword';
    case '/verify-email':
      return 'verifyEmail';
    default:
      return 'login';
  }
};
