import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, Divider } from '@mui/material';
import {
  selectUser,
  selectUserEmail,
  selectUserName,
  selectUserMembershipLevel,
} from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';
import { Container } from 'layouts/Container/Container';
import Map from 'components/common/Map/Map';
import { endpoints } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { SocialMedia } from 'components/common/SocialMedia/SocialMedia';
import {
  getFirestore,
  collection,
  query,
  getDocs,
  where,
} from 'firebase/firestore';
import { MainReportsBlock } from 'components/common/MainReportsBlock/MainReportsBlock';
import './MainPage.style.css';
import { useParams } from 'react-router-dom';
// import AdvertisementBlock from 'components/ui/Advertisement/AdvertisementBlock';
// import AdSenseBlock from 'components/ui/AdSens';

const HomePage = ({ center }) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const nameUser = useSelector(selectUserName);
  const emailUser = useSelector(selectUserEmail);
  const superUser = useSelector(selectUserMembershipLevel);
  const today = new Date().toLocaleDateString();
  const isScreenLessThan1024px = useMediaQuery('(max-width:1023px)');
  const [endpointData, setEndpointData] = useState({});
  const [endpointChecks, setEndpointChecks] = useState({});
  const db = getFirestore();
  const { trendingCountry } = useParams();

  const smoothScrollToTop = () => {
    window.scrollTo({
      top: -1,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.title = 'SleuthNet';
    smoothScrollToTop();
  }, []);

  // const thirtyDaysAgo = useRef(new Date());

  // thirtyDaysAgo.current.setDate(thirtyDaysAgo.current.getDate() - 30);

  useEffect(() => {
    const fetchData = async () => {
      const reportsCollection = collection(db, 'Reports');
      const queryRef = query(
        reportsCollection,
        // where('timeObserved', '>=', thirtyDaysAgo.current),
        where('archived', '==', false)
      );

      try {
        const querySnapshot = await getDocs(queryRef);
        const newEndpointData = {};

        querySnapshot.forEach(doc => {
          const docData = doc.data();
          const { reportType } = docData;

          if (reportType) {
            if (!newEndpointData[reportType]) {
              newEndpointData[reportType] = [];
            }
            newEndpointData[reportType].push({ id: doc.id, ...docData });
          }
        });

        setEndpointData(newEndpointData);

        // Set all endpoint checks to true if data was loaded
        const updatedEndpointChecks = {};
        Object.keys(newEndpointData).forEach(reportType => {
          updatedEndpointChecks[reportType] = true;
        });
        setEndpointChecks(updatedEndpointChecks);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchData();
  }, [db]);

  return (
    <Container>
      <Box className="MainBox">
        {user && (
          <Box className="UserMainBox">
            <Typography>
              {t('mainPage.welcome')},{' '}
              {nameUser ? (
                <strong>{nameUser}</strong>
              ) : (
                <strong>{emailUser}</strong>
              )}
            </Typography>
            <Typography textAlign={'end'}>
              {t('mainPage.today')}: <strong>{today}</strong>
            </Typography>
          </Box>
        )}

        <Box
          className="UserMainContectsBox"
          sx={{
            alignItems: isScreenLessThan1024px ? 'center' : 'start',
            flexDirection: isScreenLessThan1024px ? 'column' : 'row',
          }}
        >
          <Box
            flexDirection={'column'}
            sx={{ width: isScreenLessThan1024px ? '100%' : '70%' }}
          >
            {superUser === 'SuperUser' && (
              <MainReportsBlock
                endpointData={endpointData}
                userMemberShipLevel={superUser}
              />
            )}

            {/* <AdSenseBlock
                  style={{
                    width: '100%',
                    minHeight: '8rem',
                    color: 'black',
                    marginBottom: '1rem',
                  }}
                /> */}

            {/* <AdvertisementBlock
              style={{
                width: '100%',
                minHeight: '8rem',
                color: 'black',
                marginBottom: '1rem',
              }}
              children={' Advertisement'}
            /> */}

            <Map
              setEndpointChecks={setEndpointChecks}
              endpointChecks={endpointChecks}
              endpointData={endpointData}
              endpoints={endpoints}
              center={center}
            />
          </Box>

          {!isScreenLessThan1024px ? (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ marginX: '16px !important' }}
            />
          ) : (
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ marginY: '16px !important' }}
            />
          )}
          <Box
            sx={{
              width: isScreenLessThan1024px ? '100%' : 400,
              display: 'inline',
              height: superUser === 'SuperUser' ? 808 : 552,
              overflow: 'auto',
            }}
          >
            {/* <AdvertisementBlock
              style={{
                marginBottom: '1rem',
                height: '10rem',
                color: 'black',
              }}
              children={'Advertisement'}
            /> */}
            <Box className="SocialMediaBox">
              <Typography variant="h2">
                {t('mainPage.news')} {trendingCountry}
              </Typography>
              <SocialMedia trendingCountry={trendingCountry} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default HomePage;
