import styled from '@emotion/styled';
import {
  mediaTablet,
  mediaDesktop,
  mediaMaxTablet,
  mediaUHD,
  mediaPhone,
} from 'utils/media';

export const ContainerBody = styled.div`
  max-width: 360px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;

  ${mediaPhone(`
  max-width: 600px;
  padding: 0 10px;
  `)}

  ${mediaMaxTablet(`
  max-width: 800px;
  padding: 0 10px;
  `)}

  ${mediaTablet(`
  max-width: 1000px;
  padding: 0 16px;
  
  `)}

  ${mediaDesktop(`
  max-width: 1200px;
  padding: 0 16px;
  `)}

${mediaUHD(`
  max-width: 1360px;
  padding: 0 16px;
  `)}
`;
