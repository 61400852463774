import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  useMediaQuery,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import json2mq from 'json2mq';
import './MainReportsBlock.style.css';
import { useDispatch } from 'react-redux';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import { useNavigate } from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';


export const MainReportsBlock = ({ endpointData, userMemberShipLevel }) => {
  const { t } = useTranslation();
  const push = useNavigate();
  const dispatch = useDispatch();
  const [latestReports, setLatestReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery(
    json2mq({
      minWidth: 500,
    })
  );
  useEffect(() => {
    setLoading(true);
    const allReports = Object.values(endpointData).reduce(
      (acc, endpointReports) => [...acc, ...endpointReports],
      []
    );
    const filteredReports = allReports.filter(
      report =>
        (report.verificationStatus === 0 &&
          report.closedForUpdates === false) ||
        typeof report.verificationStatus === 'undefined'
    );

    filteredReports.sort((a, b) => b.timeObserved - a.timeObserved);

    const latestThreeReports = filteredReports.slice(0, 3);

    setLatestReports(latestThreeReports);
    setLoading(false);
  }, [endpointData]);

  const handleUpdateReportClick = report => {
    dispatch(setSelectedReport(report));
    push('/update-report');
  };

  const handleViewDetailsClick = report => {
    setSelectedReport(report);
    push(`/report/view/${report.reportId}`);
  };

  return (
    <Box className="mainReportsBox">
      <Box justifyContent={'space-between'}>
        <Typography variant="h2">{t('mainReportBlock.title')}</Typography>

        <Link to={`/report`}>
        <Button variant="contained">
            <AddTwoToneIcon />
            <Typography
              ml={1}
              sx={{ textTransform: 'capitalize !important', color: 'inherit' }}
            >
              {t('mainReportBlock.addBtn')}{' '}
            </Typography>
          </Button>
        </Link>
      </Box>
      {loading && (
        <Box
          sx={{
            justifyContent: 'center',
            marginTop: 3,
          }}
        >
          <CircularProgress color="primary" size={60} />
        </Box>
      )}
      {latestReports.length === 0 ? (
        <Typography sx={{ marginTop: 3 }}>Nothing for you review</Typography>
      ) : (
        <Table>
          <tbody>
            {latestReports.map(report => (
              <TableRow key={report.id} className="itemReportBox">
                <TableCell className="itemReportText">
                  {userMemberShipLevel === 'SuperUser' ? (
                    <Button
                      variant="outlined"
                      onClick={() => handleUpdateReportClick(report)}
                    >
                      {t('mainReportBlock.view')}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleViewDetailsClick(report)}
                    >
                      {t('mainReportBlock.view')}
                    </Button>
                  )}
                </TableCell>
                <TableCell className="itemReportText">
                  {report.reportName} <br />
                  {report.type || report.typeBomb}
                </TableCell>
                {matches && (
                  <TableCell className="itemReportText">
                    {report.country || '-'}
                  </TableCell>
                )}
                {matches && (
                  <TableCell className="itemReportText">
                    <Typography variant="error">
                      {t('mainReportBlock.unverified')}
                    </Typography>
                  </TableCell>
                )}
                <TableCell className="itemReportText">
                  {new Date(
                    report.timeObserved.seconds * 1000
                  ).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
    </Box>
  );
};
