import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import GoogleStoreButton from 'components/common/GoogleStore/GoogleStoreButton';
import logo1 from 'assets/img/logoHead.png';
import logo2 from 'assets/img/titleLogo.png';
import { ROUTES } from 'routes';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import json2mq from 'json2mq';
import { Container } from 'layouts/Container/Container';
import './Footer.style.css';

export const Footer = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery(
    json2mq({
      minWidth: 400,
    })
  );
  return (
    <footer>
      <Box className="footerMainBox">
        <Container>
          <Grid className="footerGrid" container spacing={3}>
            <Grid item xs={12} sm={3}>
              <NavLink to="/" exact="true" className="navLink">
                {t('footer.home')}
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={3}>
              <NavLink to={ROUTES.policy.path} className="navLink">
                {t('footer.policy')}
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={3}>
              <NavLink to={ROUTES.about.path} className="navLink">
                {t('footer.about')}
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={3}>
              <NavLink
                to={ROUTES.contacts.path}
                exact="true"
                className="navLink"
              >
                {t('footer.contacts')}
              </NavLink>
            </Grid>
          </Grid>
          <Box sx={{ marginX: 'auto', width: 196 }}>
            <GoogleStoreButton />
          </Box>
          <Box
            sx={{
              justifyContent: 'center',
              py: 2,
            }}
          >
            <Link to="/">
              <img
                alt="logo"
                src={logo1}
                style={{ height: '40px', width: '52px' }}
              />
            </Link>
            {matches && (
              <img
                alt="logo2"
                src={logo2}
                style={{ width: '90px', marginRight: '8px' }}
              />
            )}

            <Typography variant="body2" color="white">
              &copy; {new Date().getFullYear()} ArcLight Technologies
            </Typography>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
