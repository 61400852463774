import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';

export const ShareReport = () => {
    const [ reportData, setReportData ] = useState(null);
    const {reportId } = useParams();

    useEffect(() => {
        async function fetchReport() {
            try {
                const docRef = doc(db, 'militaryActivityReport', reportId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setReportData(docSnap.data());
                } else {
                    console.log("No such document.");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            }
        }

        fetchReport();

    }, [reportId]);


  return (
    <>
    <div>Report ID: { reportId } </div>
    
    <div>Report Details: { JSON.stringify(reportData) }</div>
    </>
  )
}
