import { createSlice } from '@reduxjs/toolkit';

const updateReportSlice = createSlice({
  name: 'selectedReport',
  initialState: null,
  reducers: {
    setSelectedReport: (_state, action) => {
      return action.payload;
    },
  },
});

export const selectSelectedReport = state => state.selectedReport;
export const { setSelectedReport } = updateReportSlice.actions;
export default updateReportSlice.reducer;
