import { useState } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Toolbar,
  Box,
  AppBar,
  useMediaQuery,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { SideBar } from 'components/common/SideBar/SideBar';
import logo1 from 'assets/img/logoHead.png';
import logo2 from 'assets/img/titleLogo.png';
import { selectUser } from 'redux/auth/authSlice';
import { SwitchLanguage } from 'components/common/SwitchLanguage/SwitchLanguage';
import json2mq from 'json2mq';
import { Container } from 'layouts/Container/Container';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import './MainAppBar.style.css';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

const appBarStyle = {
  position: 'relative',
  zIndex: 999,
  background: '#101010',
};

export const MainAppBar = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const location = useLocation();
  const matches = useMediaQuery(
    json2mq({
      minWidth: 811,
    })
  );

  const [isNavButtonsOpen, setNavButtonsOpen] = useState(false);
  const [, setArrowButtonClicked] = useState(false);

  const toggleNavButtons = () => {
    setNavButtonsOpen(!isNavButtonsOpen);
  };

  const handleArrowButtonClick = () => {
    toggleNavButtons();
    setArrowButtonClicked(true);
  };

  return (
    <AppBar style={appBarStyle}>
      <Container>
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 0 !important',
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box>
              <SideBar />
              <Link to="/">
                <Box>
                  <img
                    alt="logo"
                    src={logo1}
                    style={{ height: '40px', width: '52px' }}
                  />
                  {''}
                  {matches && (
                    <img
                      alt="logo2"
                      src={logo2}
                      style={{
                        paddingLeft: '8px',
                        width: '160px',
                        height: '18px',
                      }}
                    />
                  )}
                </Box>
              </Link>
            </Box>

            <Box
              sx={{
                justifyItems: 'center',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: 'auto',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: 1,
                }}
              >
                {location.pathname !== '/' && (
                  <Link sx={{ color: '#B9B9B9' }} component={Link} to="/">
                    {matches ? (
                      <Button
                        aria-label="flex button group"
                        sx={{
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          mr: 1,
                        }}
                      >
                        {t('appBar.mainPage')}
                      </Button>
                    ) : (
                      <Tooltip title={t('appBar.mainPage')}>
                        <IconButton>
                          <HomeOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Link>
                )}

                {matches && (
                  <>
                    <Link
                      sx={{ color: '#B9B9B9' }}
                      component={Link}
                      to="/fullMap"
                    >
                      <Button
                        aria-label="flex button group"
                        sx={{
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          mr: 1,
                        }}
                      >
                        {t('appBar.map')}
                      </Button>
                    </Link>

                    {user && (
                      <Link to={`/report`}>
                        <Button
                          aria-label="flex button group"
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                            mr: 1,
                          }}
                        >
                          {t('pageReport.addBtn')}
                        </Button>
                      </Link>
                    )}
                  </>
                )}
              </Box>

              <Box mr={2}>
                {!user && (
                  <Link to="/sign-in/login">
                    <IconButton size="large">
                      <LoginRoundedIcon />
                    </IconButton>
                  </Link>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                textTransform: 'capitalize',
                fontSize: '14px',
              }}
              className="SwichLangBox"
            >
              <SwitchLanguage />
            </Box>
          </Box>

          <Box>
            {!matches && (
              <Accordion expanded={isNavButtonsOpen}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={handleArrowButtonClick}
                  sx={{
                    p: 0.5,
                    minHeight: '24px !important',
                    background: '#101010',
                    m: '0 !important',
                  }}
                />
                <AccordionDetails
                  sx={{ width: '100%', p: 0, background: '#101010' }}
                >
                  {isNavButtonsOpen && (
                    <>
                      <Link
                        sx={{ color: '#B9B9B9' }}
                        component={Link}
                        to="/fullMap"
                      >
                        <Button
                          aria-label="flex button group"
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                            mr: 1,
                          }}
                        >
                          {t('appBar.map')}
                        </Button>
                      </Link>
                      {user && (
                        <Link to={`/report`}>
                          <Button
                            aria-label="flex button group"
                            sx={{
                              textTransform: 'capitalize',
                              fontWeight: 'bold',
                              mr: 1,
                            }}
                          >
                            {t('pageReport.addBtn')}
                          </Button>
                        </Link>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
