import MobileStoreButton from 'react-mobile-store-button';
import React from 'react';

export default class GoogleStoreButton extends React.Component {
  render() {
    const AndroidUrl = 'https://play.google.com/store/apps/details?id=com.sentinelArclight';
    return (
      <div>
        <MobileStoreButton
          store="android"
          url={AndroidUrl}
          linkProps={{ title: 'Google Store Button' }}
        />
      </div>
    );
  }
}
