import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from '../redux/auth/authSlice';

export function PrivateRoute({ children, ...routeProps }) {
  const user = useSelector(selectUser);

  return (
    <>{user ? children : <Navigate to="/sign-in/login" replace={false} />}</>
  );
}
