import React, { useState, useEffect } from 'react';
import { Modal, Box, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const VideoViewer = ({ open, onClose, videos }) => {
  const [loading, setLoading] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const moreVideos = videos.length > 1;

  const handleNextVideo = () => {
    setCurrentVideoIndex(prevIndex => (prevIndex + 1) % videos.length);
  };

  const handlePreviousVideo = () => {
    setCurrentVideoIndex(prevIndex =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [videos]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="video-viewer-modal-title"
      aria-describedby="video-viewer-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          minWidth: '360px',
          maxWidth: '800px',
          height: '90vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          paddingY: 5,
          paddingX: 1,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            transition: 'background-color 0.3s',
            ':hover': {
              backgroundColor: '#2196f3',
            },
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {loading ? (
          <Box
            sx={{
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {moreVideos && (
              <IconButton
                aria-label="previous"
                onClick={handlePreviousVideo}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  transform: 'translateY(-50%)',
                  transition: 'background-color 0.3s',
                  ':hover': {
                    backgroundColor: '#2196f3',
                  },
                  zIndex: 1,
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
            {videos.map((video, index) => (
              <video
                key={index}
                controls
                style={{
                  width: '100%',
                  height: '100%',
                  display: index === currentVideoIndex ? 'block' : 'none',
                }}
                src={video}
              >
                Your browser does not support the video tag.
              </video>
            ))}
            {moreVideos && (
              <IconButton
                aria-label="next"
                onClick={handleNextVideo}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  transition: 'background-color 0.3s',
                  ':hover': {
                    backgroundColor: '#2196f3',
                  },
                  zIndex: 1,
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default VideoViewer;
